var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('nav',{staticClass:"breadcrumb",attrs:{"aria-label":"breadcrumbs"}},[_c('ul',[_c('li',[_c('router-link',{attrs:{"to":{ name: 'Dashboard' }}},[_c('b-icon',{attrs:{"icon":"home"}})],1)],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'BillingIndex' }}},[_vm._v(" Billings ")])],1),_c('li',{staticClass:"has-text-primary-dark-blue"},[(_vm.$route.path.includes('/addbilling'))?_c('span',{staticClass:"px-2"},[_vm._v(" Add Billing ")]):_c('span',{staticClass:"px-2"},[_vm._v(" Edit Billing ")])])])]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.save)}}},[_c('div',{staticClass:"buttons",staticStyle:{"justify-content":"flex-end"}},[_c('b-button',{staticStyle:{"border":"none","background-color":"transparent"},on:{"click":function($event){return _vm.cancelAction()}}},[_vm._v(" Batalkan ")]),_c('b-button',{attrs:{"type":"is-primary-medium-blue","native-type":"submit"}},[_vm._v(" Simpan ")])],1),_c('ValidationProvider',{attrs:{"name":"Nama Billing","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',[_c('template',{slot:"label"},[_c('span',{staticClass:"is-size-7"},[_vm._v(" Nama Billing ")])]),_c('b-input',{attrs:{"placeholder":"Masukan Nama Billing","type":"text"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],2),(errors != null)?_c('p',{staticClass:"has-text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Masa Aktif","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',[_c('template',{slot:"label"},[_c('span',{staticClass:"is-size-7"},[_vm._v(" Masa Aktif (Hari) ")])]),_c('b-input',{attrs:{"placeholder":"Masukan Jumlah Masa Aktif (hari)","type":"text","value":_vm.form.expiry},on:{"input":_vm.updateExpiry},nativeOn:{"keypress":function($event){return _vm.isNumber($event)}},model:{value:(_vm.expiryStr),callback:function ($$v) {_vm.expiryStr=$$v},expression:"expiryStr"}})],2),(errors != null)?_c('p',{staticClass:"has-text-red"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Harga","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',[_c('template',{slot:"label"},[_c('span',{staticClass:"is-size-7"},[_vm._v(" Harga ")])]),_c('b-input',{attrs:{"placeholder":"Masukan Harga","type":"text","value":_vm.form.price},on:{"input":_vm.updatePrice},nativeOn:{"keypress":function($event){return _vm.isNumber($event)}},model:{value:(_vm.priceStr),callback:function ($$v) {_vm.priceStr=$$v},expression:"priceStr"}})],2),(errors != null)?_c('p',{staticClass:"has-text-red"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Diskon","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',[_c('template',{slot:"label"},[_c('span',{staticClass:"is-size-7"},[_vm._v(" Diskon ")])]),_c('b-input',{attrs:{"placeholder":"Masukan Diskon","type":"text","value":_vm.form.discount},on:{"input":_vm.updateDiscount},nativeOn:{"keypress":function($event){return _vm.isNumber($event)}},model:{value:(_vm.discountStr),callback:function ($$v) {_vm.discountStr=$$v},expression:"discountStr"}})],2),(errors != null)?_c('p',{staticClass:"has-text-red"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Informasi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',[_c('template',{slot:"label"},[_c('span',{staticClass:"is-size-7"},[_vm._v(" Informasi ")])]),_c('b-input',{attrs:{"placeholder":"Masukan Information","type":"text"},model:{value:(_vm.form.information),callback:function ($$v) {_vm.$set(_vm.form, "information", $$v)},expression:"form.information"}})],2),(errors != null)?_c('p',{staticClass:"has-text-red"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }