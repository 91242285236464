<template>
  <div>
    <nav class="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li>
          <router-link :to="{ name: 'Dashboard' }">
            <b-icon icon="home"></b-icon>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'BillingIndex' }">
            Billings
          </router-link>
        </li>
        <li class="has-text-primary-dark-blue">
          <span class="px-2" v-if="$route.path.includes('/addbilling')">
            Add Billing
          </span>
          <span class="px-2" v-else>
            Edit Billing
          </span>
        </li>
      </ul>
    </nav>

    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(save)">
        <div class="buttons" style="justify-content: flex-end">
          <b-button
            @click="cancelAction()"
            style="border: none;background-color:transparent"
          >
            Batalkan
          </b-button>

          <b-button type="is-primary-medium-blue" native-type="submit">
            Simpan
          </b-button>
        </div>

        <ValidationProvider
          name="Nama Billing"
          rules="required"
          v-slot="{ errors }"
        >
          <b-field>
            <template slot="label">
              <span class="is-size-7">
                Nama Billing
              </span>
            </template>
            <b-input
              v-model="form.name"
              placeholder="Masukan Nama Billing"
              type="text"
            ></b-input>
          </b-field>
          <p class="has-text-danger" v-if="errors != null">
            {{ errors[0] }}
          </p>
        </ValidationProvider>

        <ValidationProvider
          name="Masa Aktif"
          rules="required"
          v-slot="{ errors }"
        >
          <b-field>
            <template slot="label">
              <span class="is-size-7">
                Masa Aktif (Hari)
              </span>
            </template>
            <b-input
              v-model="expiryStr"
              placeholder="Masukan Jumlah Masa Aktif (hari)"
              type="text"
              :value="form.expiry"
              @keypress.native="isNumber($event)"
              @input="updateExpiry"
            ></b-input>
          </b-field>
          <p class="has-text-red" v-if="errors != null">
            {{ errors[0] }}
          </p>
        </ValidationProvider>
        <ValidationProvider name="Harga" rules="required" v-slot="{ errors }">
          <b-field>
            <template slot="label">
              <span class="is-size-7">
                Harga
              </span>
            </template>
            <b-input
              v-model="priceStr"
              placeholder="Masukan Harga"
              type="text"
              :value="form.price"
              @keypress.native="isNumber($event)"
              @input="updatePrice"
            ></b-input>
          </b-field>
          <p class="has-text-red" v-if="errors != null">
            {{ errors[0] }}
          </p>
        </ValidationProvider>

        <ValidationProvider name="Diskon" rules="required" v-slot="{ errors }">
          <b-field>
            <template slot="label">
              <span class="is-size-7">
                Diskon
              </span>
            </template>
            <b-input
              v-model="discountStr"
              placeholder="Masukan Diskon"
              type="text"
              :value="form.discount"
              @keypress.native="isNumber($event)"
              @input="updateDiscount"
            ></b-input>
          </b-field>
          <p class="has-text-red" v-if="errors != null">
            {{ errors[0] }}
          </p>
        </ValidationProvider>

        <ValidationProvider
          name="Informasi"
          rules="required"
          v-slot="{ errors }"
        >
          <b-field>
            <template slot="label">
              <span class="is-size-7">
                Informasi
              </span>
            </template>
            <b-input
              v-model="form.information"
              placeholder="Masukan Information"
              type="text"
            ></b-input>
          </b-field>
          <p class="has-text-red" v-if="errors != null">
            {{ errors[0] }}
          </p>
        </ValidationProvider>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        id: '',
        name: '',
        expiry: '',
        price: '',
        information: '',
        discount: '',
      },
      priceStr: '',
      expiryStr: '',
      discountStr: '',
    }
  },
  created() {
    if (this.$route.path !== '/billings/addbilling') {
      this.getBillingData(this.$route.params.billingid)
    }
  },
  methods: {
    updateDiscount(event) {
      event = String(event).replace(/,/g, '')
      this.form.discount = event
      // comas discount
      this.discountStr = this.form.discount
      let resdiscountStr = this.discountStr
        .toString()
        .replace(/\D/g, '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      this.discountStr = resdiscountStr
    },
    updatePrice(event) {
      event = String(event).replace(/,/g, '')
      this.form.price = event
      // comas price
      this.priceStr = this.form.price
      let respriceStr = this.priceStr
        .toString()
        .replace(/\D/g, '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      this.priceStr = respriceStr
    },
    updateExpiry(event) {
      event = String(event).replace(/,/g, '')
      this.form.expiry = event
      // comas expiry
      this.expiryStr = this.form.expiry
      let resexpiryStr = this.expiryStr
        .toString()
        .replace(/\D/g, '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      this.expiryStr = resexpiryStr
    },
    isNumber: function(evt) {
      evt = evt ? evt : window.event
      var charCode = evt.which ? evt.which : evt.keyCode
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    getBillingData(id) {
      this.$store.dispatch('getBillingData', { id }).then(data => {
        this.form = data

        //commas price
        this.priceStr = this.form.price
        let respriceStr = this.priceStr
          .toString()
          .replace(/\D/g, '')
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        this.priceStr = respriceStr

        //commas expiry
        this.expiryStr = this.form.expiry
        let resexpiryStr = this.expiryStr
          .toString()
          .replace(/\D/g, '')
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        this.expiryStr = resexpiryStr

        // comas discount
        this.discountStr = this.form.discount
        let resdiscountStr = this.discountStr
          .toString()
          .replace(/\D/g, '')
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        this.discountStr = resdiscountStr
      })
    },
    save: function() {
      this.$buefy.dialog.confirm({
        title: 'Konfirmasi',
        message: 'Apakah Anda Yakin Menyimpan Data Ini?',
        confirmText: 'Ya',
        hasIcon: true,
        onConfirm: () => {
          let name = this.form.name
          let id = this.form.id
          let expiry = parseInt(this.form.expiry)
          let price = parseInt(this.form.price)
          let information = this.form.information
          let discount = parseInt(this.form.discount)
          if (id === '') {
            this.$store
              .dispatch('addNewBilling', {
                name,
                expiry,
                price,
                information,
                discount,
              })
              .then()
              .catch(err => console.log(err))
          } else {
            this.$store
              .dispatch('editBilling', {
                name,
                id,
                expiry,
                price,
                information,
                discount,
              })
              .then()
              .catch(err => console.log(err))
          }
        },
      })
    },
    cancelAction() {
      this.$buefy.dialog.confirm({
        title: 'Konfirmasi',
        message: 'Apakah anda yakin membatalkan aksi ini?',
        confirmText: 'Ya',
        type: 'is-warning',
        hasIcon: true,
        onConfirm: () => {
          this.$router.push('/billings')
        },
      })
    },
  },
}
</script>

<style></style>
